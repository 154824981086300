import React, { useState, useContext, useEffect } from "react"
import {Form, Input, Button} from '../components/common'
import {FirebaseContext} from '../components/Firebase'
import Containet from '../components/container'

const AddAuthor = () => {
  const {firebase, user} = useContext(FirebaseContext);
  const [brandName, setBrandName] = useState('');
  const [brandText, setBrandText] = useState('');

  
  const [success, setSuccess] = useState(false);

  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    }
  }, [])

  function handleSubmit(e){
    e.preventDefault();
    firebase.createBrand({
      brandName,brandText
    }).then(() => {
      if(isMounted) {
        setBrandName('');
        setSuccess(true);
      }
    })
  }

  return (
      <Containet>
        
        {(!!user ) ?
            <span>
                { Number(user.level) >= 5 ?
                <Form onSubmit={handleSubmit}>
                <Input onChange={(e) => {
                    e.persist();
                    setSuccess(false);
                    setBrandName(e.target.value);
                }} value={brandName} placeholder="Brand" />

                 <Input onChange={(e) => {
                    e.persist();
                    setSuccess(false);
                    setBrandText(e.target.value);
                }} value={brandText} placeholder="Text" />

                {!!success &&
                <span>
                    Author created successfully!
                </span>
                }
                <Button type="submit" block>
                    Add new brand
                </Button>
                </Form> 
                :
                <span> Access Denied, contact administrator </span>
                } 
            </span>   
            :
            <span>Checking Access</span>
         }
         </Containet>
  );
}


export default AddAuthor;